var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"City","label-for":"user-status"}},[_c('validation-provider',{attrs:{"rules":"required","name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.cityOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-status"},model:{value:(_vm.model.cityId),callback:function ($$v) {_vm.$set(_vm.model, "cityId", $$v)},expression:"model.cityId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Name Office","label-for":"nameCountry"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name Office"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Manager","label-for":"user-status"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.employeeOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-status"},model:{value:(_vm.model.id_mananger_employe),callback:function ($$v) {_vm.$set(_vm.model, "id_mananger_employe", $$v)},expression:"model.id_mananger_employe"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Adress","label-for":"nameCountry"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Adress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username"},model:{value:(_vm.model.adress),callback:function ($$v) {_vm.$set(_vm.model, "adress", $$v)},expression:"model.adress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Zip Code","label-for":"nameCountry"}},[_c('validation-provider',{attrs:{"rules":"required|integer","name":"Zip Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username"},model:{value:(_vm.model.zip_code),callback:function ($$v) {_vm.$set(_vm.model, "zip_code", $$v)},expression:"model.zip_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"user-status"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-status","type":"booleano"},model:{value:(_vm.model.status),callback:function ($$v) {_vm.$set(_vm.model, "status", $$v)},expression:"model.status"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Name Pos","label-for":"user-status"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Pos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.posOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-status"},model:{value:(_vm.model.posCodeId),callback:function ($$v) {_vm.$set(_vm.model, "posCodeId", $$v)},expression:"model.posCodeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Pos Id","label-for":"nameCountry"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Code Pos"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username"},model:{value:(_vm.model.posId),callback:function ($$v) {_vm.$set(_vm.model, "posId", $$v)},expression:"model.posId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":function($event){$event.preventDefault();return _vm.validationForm(_vm.userData.office_id)}}},[_vm._v(" Save Changes Office ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Reset ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }