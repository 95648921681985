<template>
  <div>
    <validation-observer ref="simpleRules">
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <b-form-group label="City" label-for="user-status">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="City"
              >
                <v-select
                  v-model="model.cityId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cityOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Name -->
          <b-col cols="12" md="4">
            <b-form-group label="Name Office" label-for="nameCountry">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Name Office"
              >
                <b-form-input id="username" v-model="model.name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Manager -->
          <b-col cols="12" md="4">
            <b-form-group label="Manager" label-for="user-status">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Manager"
              >
                <v-select
                  v-model="model.id_mananger_employe"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="employeeOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Adress -->
          <b-col cols="12" md="4">
            <b-form-group label="Adress" label-for="nameCountry">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Adress"
              >
                <b-form-input id="username" v-model="model.adress" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Zip Code -->
          <b-col cols="12" md="4">
            <b-form-group label="Zip Code" label-for="nameCountry">
              <validation-provider
                #default="{ errors }"
                rules="required|integer"
                name="Zip Code"
              >
                <b-form-input id="username" v-model="model.zip_code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="4">
            <b-form-group label="Status" label-for="user-status">
              <v-select
                v-model="model.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-status"
                type="booleano"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-group label="Name Pos" label-for="user-status">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Pos"
              >
                <v-select
                  v-model="model.posCodeId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="posOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: Pos Id -->
          <b-col cols="12" md="4">
            <b-form-group label="Pos Id" label-for="nameCountry">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Code Pos"
              >
                <b-form-input id="username" v-model="model.posId" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- Action Buttons -->

    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click.prevent="validationForm(userData.office_id)"
    >
      Save Changes Office
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useUsersList from "../country-list/useUsersList";
import userStoreModule from "../userStoreModule";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      required: true,
    },
  },
  methods: {
    validationForm(id) {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          this.updateCountry(id);
        }
      });
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-user";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();

    const { resolveUserRoleVariant } = useUsersList();

    const statusOptions = [
      { label: "Active", value: true },
      { label: "Inactive", value: false },
    ];
    const userData1 = ref(null);
    const cityOptions = ref([]);
    const employeeOptions = ref([]);
    const posOptions = ref([]);
    const posData = [];

    const {
      city_id,
      office_name,
      employees_id,
      office_status,
      office_adress,
      office_id,
      office_zip_code,
      office_posId,
      office_posCode,
      pos_name,
      pos_id,
    } = props.userData[0];

    console.log("props.userData[0]", props.userData[0]);

    const model = ref({
      id: office_id,
      name: office_name,
      cityId: city_id,
      id_mananger_employe: employees_id,
      adress: office_adress,
      status: office_status,
      zip_code: office_zip_code,
      posId: office_posId,
      posCodeId: pos_id,
    });

    const city = [];
    const employees = [];
    store
      .dispatch("app-user/fetchHelpers")
      .then((response) => {
        const { cities, employee, pos } = response.data.data;

        cities.map((data, item) => {
          city.push({ label: data.name, value: data.id, id: item });
        });

        employee.map((data, item) => {
          employees.push({
            label: `${data.first_name} ${data.last_name}`,
            value: data.id,
            id: item,
          });
        });
        pos.map((data, item) => {
          posData.push({ label: data.name, value: data.id, id: item });
        });

        cityOptions.value = city;
        employeeOptions.value = employees;
        posOptions.value = posData;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    const updateCountry = (id) => {
      store
        .dispatch("app-user/updateCountry", {
          model,
        })
        .then((response) => {
          userData1.value = response.data;
          toast({
            component: ToastificationContent,
            props: {
              title: `The office  was created correctly`,
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
          router.push({ name: "apps-office-list" });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData1.value = undefined;
          }
        });
    };

    return {
      resolveUserRoleVariant,
      avatarText,
      statusOptions,
      cityOptions,
      employeeOptions,
      posOptions,
      model,

      updateCountry,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
